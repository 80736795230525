import React, { useState } from 'react';
import '../Home/Home.css';
import Header from '../Header/Header';
import ShortFooter from '../ShortFooter/ShortFooter';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';
import { useAuth } from '../../AuthContext';

const SignIn = () => {

    const [isSignup, setIsSignup] = useState(false);
    const [loading, setLoading] = useState(false);
    const { dispatch } = useAuth();

    const toggleForm = () => {
        setIsSignup(!isSignup);
    };

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        postcode: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSignup = () => {
        fetch(API_URL + '/create-account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                address: formData.address,
                city: formData.city,
                country: formData.country,
                postcode: formData.postcode,
                password: formData.password
            }),
        })
            .then(response => response.json())
            .then(async data => {
                if (data.error) {
                    alert(data.error);
                } else {
                    window.alert("user created successfully!")
                    toggleForm();
                }
            })
            .catch(error => {
                console.error('Error creating account:', error);
            });
    }

    const handleSignIn = async (e) => {
        try {
            setLoading(true);
            const response = await fetch(API_URL + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.status === 200) {
                const data = await response.json();
                dispatch({ type: 'SIGN_IN', payload: { user: data.user } });
                navigate('/Account')
                setLoading(false);
            } else if (response.status === 401) {
                alert('Invalid credentials.');
                setLoading(false);
            } else {
                alert('Login failed');
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    return (
        <div>
            <Header />
            <br />
            <div className='bg-[#FAFAFA] pt-[12%] lg:pt-[5%] 2xl:pt-[6%]'>
                <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>

                    <div className=" container_SignIn">
                        <div className={`slider ${isSignup ? 'moveslider' : ''}`}></div>
                        <div className="btn">
                            <button className={`login ${!isSignup ? 'active' : ''}`} onClick={() => toggleForm()} name='signIn_btn'>SIGN IN</button>
                            <button className={`signup ${isSignup ? 'active' : ''}`} onClick={() => toggleForm()} name='signUp_btn'>SIGN UP</button>
                        </div>

                        <div className={`form-section ${isSignup ? 'form-section-move' : ''}`}>
                            <form className="login-box" onSubmit={handleSignIn}>
                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Phone number*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter phone number"
                                            name="email" value={formData.email} onChange={handleInputChange} />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Password*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="password"
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter password"
                                            name="password" value={formData.password} onChange={handleInputChange} />
                                    </div>
                                </div>

                                {loading ? <button className='flex w-full justify-center my-[5%] lg:my-[2%] border-[1px] border-[#333333] rounded-full px-[4%] py-[0.3%] bg-[#333333] text-white hover:bg-transparent hover:text-[#333333] lg:text-[14px] 2xl:text-2xl' disabled style={{ opacity: 0.5 }} name='singIn_btn'>
                                    SIGN&nbsp;IN
                                </button> :
                                    <button className='flex w-full justify-center my-[5%] lg:my-[2%] border-[1px] border-[#333333] rounded-full px-[4%] py-[0.3%] bg-[#333333] text-white hover:bg-transparent hover:text-[#333333] lg:text-[14px] 2xl:text-2xl' onClick={() => handleSignIn()} name='singIn_btn'>
                                        SIGN&nbsp;IN
                                    </button>}


                                <a href='/ForgotPassword' className='my-[4%] lg:my-[2%] w-[100%] flex items-center text-center justify-center hover:underline'>
                                    Have you forgotten your password?
                                </a>
                            </form>

                            <div className="signup-box">
                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">First name*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter first name" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Last name*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter last name" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Email*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter email" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Phone number*</label>
                                    <div className="flex mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter phone number" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Address*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter country" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Country*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="country"
                                            value={formData.country}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter country" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">City*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter City" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Postcode*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="text"
                                            name="postcode"
                                            value={formData.postcode}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter country" />
                                    </div>
                                </div>

                                <div className='my-[4%] lg:my-[2%] w-[100%]'>
                                    <label className="block text-sm font-medium">Password*</label>
                                    <div className="mt-1 border-b border-gray-300">
                                        <input
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            className="block w-full border-0 border-b border-transparent bg-[#fafafa] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                                            placeholder="Enter password" />
                                    </div>
                                </div>

                                <button className='flex w-full justify-center my-[5%] lg:my-[2%] border-[1px] border-[#333333] rounded-full px-[4%] py-[0.3%] bg-[#333333] text-white hover:bg-transparent hover:text-[#333333] lg:text-[14px] 2xl:text-2xl' onClick={() => handleSignup()} name='signUp_btn'>
                                    SIGN&nbsp;UP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
            <ShortFooter />
        </div>
    );
};

export default SignIn;
